import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import GiftCards from './GiftCards'
import Spirits from './Spirits'
import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/", "/menu", "/order"]

export const pages = [
  {path: "/order", label: "Order" },
  {path: "/menu", label: "Menu" },
  // {path: "/gift-cards", label: "Gift Cards", component: GiftCards },
  // {path: "https://thenewdealrestaurant.com/", external: true, label: "Sister Location" },

]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  // { url: "https://afag.imgix.net/tortas-mexico/meat.jpeg"},
  { url: "https://afag.imgix.net/western-wok/54-mixed-veggie.jpg"},
  { url: "https://afag.imgix.net/western-wok/28-orange-chicken-1.jpg"},
  { url: "https://afag.imgix.net/western-wok/27-kung-pao-chicken.jpg"},
  { url: "https://afag.imgix.net/western-wok/43-broccoli-beef.jpg"}
]

const news = [
  {
    url: "https://afag.imgix.net/romancing-the-bean-cafe/new-drink.jpg",
    text: `We are so excited to have our newest drink @drink.renude ‘s CHAGACCINO in the Café! It’s been a hit and has so many health benefits 🙏🏼 Stress-relief, Immune boosting, and Anti-Aging are a few of the health benefits this drink offers. Have it hot or cold, here or to-go and served with your choice of milk! `
  },
  {
    url: "https://afag.imgix.net/romancing-the-bean-cafe/t-shirt.jpg",
    text: `We’ve got you covered with Fall Gear from The Bean 🙏🏼❄️ Sweaters and T-Shirts are available in XS-XL sizes, make sure to get your’s before they run out! 👕`
  }
]




export function Header () {

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/tortas-mexico/meat.jpeg?w=500&h=500&fit=crop"
      :
     "https://afag.imgix.net/tortas-mexico/meat.jpeg?w=500"
    return (
      <div className="landing-page-wrapper">

        <div
          className="hero-banner">
          <div className="text">
            {/* <div className="hero">
              <img src={heroUrl} alt=""/>
            </div> */}
            <div className="tag-line">
              <h2 style={{
              }}>
                 Mandarin Szechuwan Cuisine
              </h2>

              <p>
                <Link to="/order" className="order-online-button">Order Online</Link>
              </p>
              <p>
                <span className="hint">Free Delivery (3 miles) <br/> 5% cashback</span>
              </p>
              <p>
                <a href="tel:+18185669985">(818) 566-9985</a>
              </p>

              <p className="address">
                1311 N Hollywood Way, Burbank
              </p>
              {/* <p className="old-ordering">
                <a href="https://www.toasttab.com/romancing-the-bean-cafe/v2/online-order#!/" className="order-online-button-archive">order via old website</a>
              </p> */}
            </div>
            {/* <p className="small">with 5% rewards</p> */}


          </div>

        </div>


        <div className="menu">
          {/* <h1>Our Menu</h1> */}
          <div className="food-gallery">
            <div className="photo">
              <Link to="/menu">View Menu</Link>
            </div>
            {foodImages.map(({url}) => {
              return(
                <div className="photo">
                  <img src={`${url}?w=300&h=300&fit=crop`} alt=""/>
                </div>
              )
            })}
          </div>
        </div>

        {/* <div className="news-section">
          <div className="header">
            <h1>Posts</h1>
          </div>

          <div className="features">
            {news.map(({url, text}) => {
              return(
                <section className={"feature-section"}>
                  <div className="photo">
                    <img src={`${url}?w=500&h=500&fit=crop`} alt=""/>
                  </div>
                  <div className="content">

                    <div className="text">
                      <p>{text}</p>
                    </div>

                  </div>
                </section>
              )
            })}
          </div>
        </div> */}


        {/* <div className="features">

          {sections.map(({h1, sub, img, content, link, linkText, className=""}) => {
            return(
              <section className={"feature-section" + className}>
                <div className="photo">
                  <img src={img} alt=""/>
                </div>
                <div className="content">
                  <h3>{h1}</h3>
                  <div className="text">
                    <p>{content}</p>
                  </div>
                  <div className="more-content">
                    <Link className="link" to={link}>{linkText}</Link>
                  </div>
                </div>
              </section>
            )
          })}
        </div> */}




      </div>
    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isBreakfastTime = (
    (hours >= 0) && (hours < 11)
  ) || (hours < 12) && (minutes < 30)

  // const showLunchMenu = isWeekday && isLunch

  const isBreakfastMenu = tag.indexOf('breakfast') !== -1 // m-f 6am - 11am
   // m-f 11am-3pm

  if (isBreakfastMenu && !isBreakfastTime) { return false }
  // if (isDinnerSubmenu && !showLunchMenu) { return true }
  return true
}
