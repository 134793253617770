
import * as Components from "./Westernwok"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "westernwok"
}
